@use 'sass:math';
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,300i,400i,700i&display=swap');

$font_size_default: 0.9375rem;
$font_family_default: 'Nunito sans', sans-serif;
$font_family_icons: "Font Awesome 6 Free";

$font_main: normal normal normal 1.0rem/normal $font_family_default;
$font_menu: normal bold normal 1.0rem/normal $font_family_default;

@function strip-unit($value) {
    @return math.div($value, $value * 0 + 1)
}

@mixin fluid-type($max-font-size:24px, $min-font-size:16px, $min-vw:320px, $max-vw:1200px) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            font-size: $min-font-size;
            @media screen and (min-width: $min-vw) {
                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }
            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}


@font-face {
    font-family: 'icons';
    src: url('/assets/fonts/icons/icons.eot?33764919');
    src: url('/assets/fonts/icons/icons.eot?33764919#iefix') format('embedded-opentype'),
            url('/assets/fonts/icons/icons.woff2?33764919') format('woff2'),
            url('/assets/fonts/icons/icons.woff?33764919') format('woff'),
            url('/assets/fonts/icons/icons.ttf?33764919') format('truetype'),
            url('/assets/fonts/icons/icons.svg?33764919#icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="ic-"]:before, [class*=" ic-"]:before {
    font-family: "icons";
    font-style: normal;
    font-weight: normal;
    speak: never;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.ic-dashboard:before { content: '\e800'; } /* '' */
.ic-dashboard-solid:before { content: '\e801'; } /* '' */
.ic-dashboard.solid:before { content: '\e801'; } /* '' */

.ic-home:before { content: '\e802'; } /* '' */
.ic-home-solid:before { content: '\e803'; } /* '' */
.ic-home.solid:before { content: '\e803'; } /* '' */

.ic-messages:before { content: '\e804'; } /* '' */
.ic-messages-solid:before { content: '\e805'; } /* '' */
.ic-messages.solid:before { content: '\e805'; } /* '' */

.ic-more:before { content: '\e806'; } /* '' */
.ic-more-solid:before { content: '\e807'; } /* '' */
.ic-more.solid:before { content: '\e807'; } /* '' */

.ic-services:before { content: '\e808'; } /* '' */
.ic-services-solid:before { content: '\e809'; } /* '' */
.ic-services.solid:before { content: '\e809'; } /* '' */
