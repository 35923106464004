.form-check {
    margin-bottom: 0;

    .custom-checkbox {
        font-weight: normal;
        position: relative;
        padding-left: 40px;
        cursor: pointer;
        font-size: 16px;

        input {
            display: none;
            height: 0;
            width: 0;

            &:checked ~ .checkmark {
                background-color: #ff1f35;
            }

            &:checked ~ .checkmark:after {
                display: block;
            }
        }

        .checkmark {
            position: absolute;
            top: 3px;
            left: 5px;
            height: 25px;
            width: 25px;
            background-color: #fff;
            border-radius: 4px;
            margin-bottom: 30px;
            transition: 0.1s all;
            box-shadow: 0px 0px 2px 2px #e9e9e9;

            &.without-shadow {
                box-shadow: none;
            }

            &.gray {
                background-color: #eee;
            }

            &:after {
                content: "";
                position: absolute;
                display: none;

                left: 8px;
                top: 5px;
                height: 12px;
                width: 8px;
                border: solid white;
                border-width: 0 3px 3px 0;
                transform: rotate(45deg);
            }
        }

        &:hover .checkmark {
            background-color: #ccc;
        }
    }

    &.form-check-sm {
        .custom-checkbox {
            padding-left: 32px;

            .checkmark {
                height: 20px;
                width: 20px;

                &:after {
                    left: 6px;
                    top: 3px;
                }
            }
        }
    }
}
