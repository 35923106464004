@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

@import './../node_modules/primeicons/primeicons.css';

@import './../node_modules/primeng/resources/themes/bootstrap4-light-blue/theme.css';
@import './../node_modules/primeng/resources/primeng.min.css';


@import './../node_modules/ngx-sharebuttons/themes/circles';

@import 'assets/styles/custom-checkbox';
@import 'assets/styles/fonts.scss';
@import 'assets/styles/colors.scss';

// Bootstrap
@import 'assets/styles/variables';
@import "~bootstrap/scss/bootstrap";
@import 'assets/styles/bootstrap.scss';

@import 'assets/styles/primeng.scss';

body, html {
    font: $font_main;
    background-color: $bgcolor_page;
}
body.no-footer {
    background-color: $bgcolor_page;
    min-height: 100vh;
}

body {
    -webkit-overflow-scrolling: touch;
}

mark {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    border-radius: 0.25rem;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}


.insurance_btn {
    button {
        width: 100% !important;
        border-radius: 4px !important;
        background: #fff !important;
        border: 2px solid #e6e6e6 !important;
        text-align: left !important;
        color: #000 !important;
        padding: 5px;

        &.active,
        &:hover {
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
            outline: none !important;
            border: 2px solid #e6e6e6 !important;
            background: #fff !important;
        }
    }
}

.fc .fc-view-container td.fc-widget-content {
    background-color: transparent !important;
}
.fc-view-container {
    background-color: #fff;
}

app-main > *:nth-child(2) {
    display: block;
    background-color: $bgcolor_page;

    @media screen and (max-width: 991px) {
        padding-bottom: 40px;
    }
}
app-main.app > *:nth-child(2) {
    padding-bottom: 50px;
}

#cardNumber, #cardExpiry, #cardCVC {
    padding-top: 0;
    padding-bottom: 0;

    & > div {
        height: 100%;
    }
}

// [innerHTML]-based links within chat messages
.messages-content .message-item .item a,
.messages-content a.item {
    color: #4FBCFF !important;
}

img[data-lazy-img-url],
img[loading] {
    position: relative;
    font-size: 0.001rem;
    font-family: $font_family_icons;
    font-weight: 900;
    text-align: center;

    &:before {
        content: none;
    }
    &:after {
        content: "\f110";
        color: $color_text_inactive;
        font-family: $font_family_icons;
        font-weight: 900;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        box-shadow: none;
        font-size: 2.5rem;
        animation: fa-spin 1s steps(8) infinite;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}

.ql-editor {
    line-height: normal;
    padding: 5px;

    img {
        max-width: 100%;
    }
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.image-uploader-paste * {
    visibility: hidden !important;
    background: none !important;
    font-size: 0 !important;
    color: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
    outline: none !important;
    display: none !important;
}

.pac-container {
    z-index: 2048;
}

// Google Maps
.gm-style {
    .gm-style-iw-c {
        padding: 0;
    }
    .gm-ui-hover-effect {
        top: 0 !important;
        right: 0 !important
    };

    .gm-style-iw-d > div[jstcache] {
        margin: 10px !important;
    }
}
body > .pac-container {
    &:after {
        display: none !important;
        content: none !important;
    }

    .pac-item {
        font-size: 0.875rem;
        border: 0;
        cursor: pointer;
        padding-top: 3px;
        padding-bottom: 3px;
    }
}


.is-invalid .ck-editor {
    border: 2px solid #dc3545 !important;
}
.ck-editor {
    margin: 10px 0 !important;
    border-radius: 4px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.05);
    border: none;

    .ck-toolbar {
        border: none;
        background: rgba(156, 160, 161, 0.13);
        background-color: #e6e6e6 !important;
        border-radius: 12px 12px 0 0 !important;

        .ck-font-size-dropdown .ck-list__item button span {
            // font-size: 1.0rem !important;
            line-height: normal;
        }

        .ck-toolbar__items {
            .ck.ck-button:not(.ck-disabled):hover,
            a.ck.ck-button:not(.ck-disabled):hover {
                background-color: #eee;
                border-radius: 6px;
                cursor: pointer;
            }
        }
    }

    .ck-sticky-panel__content_sticky {
        background: #f5f5f5;

        .ck-toolbar {
            border-radius: 0 !important;
        }
    }

    .ck.ck-sticky-panel__content {
        border: none !important;
    }

    .ck-editor__editable_inline {
        min-height: 20vh;
        border: none;
        // border-radius: 12px !important;
        overflow: hidden;
        box-shadow: none !important;

        &.ck-focused {
            box-shadow: none !important;
            border: none !important;
        }
    }

    .ck-editor__editable.ck-content {
        box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.15) !important;
    }
}

.ck-body-wrapper {
    .ck-powered-by-balloon,
    .ck-powered-by__label,
    .ck-powered-by {
        display: none !important;
    }
}

.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_ne,
.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_se {
    max-height: 50vh;
    overflow-y: auto;
}
