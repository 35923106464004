@import './colors.scss';
@import './fonts.scss';


body {
    p-toast .p-toast {
        z-index: 1040 !important;
        max-width: calc(100% - 40px);

        .p-toast-message .p-toast-message-content {
            @media screen and (max-width: 767px) {
                padding: 0.5rem;
                padding-left: 1.0rem;

                .p-toast-summary:empty + .p-toast-detail {
                    margin-top: 0.25rem;
                }
            }
            @media screen and (max-width: 575px) {
                padding: 0.35rem;
                padding-left: 0.75rem;
            }

            .p-toast-message-icon {
                font-size: 1.5rem;
                align-self: center;

                @media screen and (max-width: 767px) {


                    font-size: 1.0rem;
                }
                @media screen and (max-width: 575px) {
                    font-size: 0.75rem;
                }
            }
        }
    }

    .ql-container {
        font-size: 1rem;
    }

    .p-menuitem-text {
        font: $font_main;
    }

    .form-control {
        border-width: 2px;

        &:not(.is-invalid) {
            box-shadow: none;
            border-color: $color_input_border_default;
        }

        &:not(:disabled):not(.disabled):not(.is-invalid):not(:active):not(:focus) {

            border-color: $color_input_border_default;
        }
    }

    .btn-primary {
        &:not(:disabled):not(.disabled) {
            background-color: $color_primary;
            border-width: 2px;

            &, &:active, &:focus, &:active:focus {
                box-shadow: none;
                background-color: $color_primary;
                border-color: $color_primary;
            }

            &:hover {
                border-color: $color_primary;
                color: $color_primary;
                background-color: $bgcolor_default;
            }

            &:active:focus {
                border-color: $bgcolor_default;
                color: $bgcolor_default;
                background-color: $color_primary;
            }
        }

        &:disabled,
        &:disabled:active,
        &.disabled,
        &.disabled:active {

            border-color: $color_secondary;
            background-color: $color_secondary;
            cursor: not-allowed;

            &:hover {
                color: $color_text_inactive;
            }
        }
    }

    .btn-primary,
    .btn-secondary,
    .btn-danger,
    .btn-success,
    .btn-warning,
    .btn-info,
    .btn-light,
    .btn-dark {
        border-width: 2px;
        border-radius: 7px;
    }

    .btn-outline-primary,
    .btn-outline-secondary,
    .btn-outline-danger,
    .btn-outline-success,
    .btn-outline-warning,
    .btn-outline-info,
    .btn-outline-light,
    .btn-outline-dark {
        border-width: 2px;
        border-radius: 7px;

        &:disabled, &.disabled {
            color: $color_outline_btn_disabled_text;
            border-color: $color_outline_btn_disabled_text;
            cursor: not-allowed;

            &:active, &:focus {
                outline-color: $color_outline_btn_disabled_text;
                box-shadow: none;
            }
        }
    }

    .btn-outline-primary {
        &:not(:disabled):not(.disabled) {
            color: $color_primary;
            border-color: $color_primary;

            &,
            &:active,
            &:focus,
            &:active:focus {
                box-shadow: none;
                border-color: $color_primary;
            }

            &:hover,
            &.active:not(:hover) {
                border-color: $color_primary;
                color: $bgcolor_default;
                background-color: $color_primary;
            }

            &:active:focus {
                border-color: $color_primary;
                color: $color_primary;
                background-color: $bgcolor_default;
            }
        }

        &:focus {
            background-color: transparent;
        }
    }

    .btn-approve {
        border-color: $color_approve;
        color: $color_text_invert;
        background-color: $color_approve;

        &:hover, &:active {
            color: $color_approve;
            background-color: $color_text_invert;
        }
    }

    .btn-outline-approve {
        border-color: $color_approve;
        color: $color_approve;
        background-color: $color_text_invert;

        &:hover, &:active {
            color: $color_text_invert;
            background-color: $color_approve;
        }
    }

    .p-dropdown-panel .p-dropdown-header {
        padding: 0.5rem;
    }

    .p-dropdown {
        box-shadow: none !important;
        border-width: 2px;

        .p-inputtext {
            font-family: "Nunito sans", sans-serif;
            font-size: 1rem;
            padding: .375rem .75rem;
            height: calc(1.5em + .75rem - 2px);

            line-height: 1.5;
        }

        .p-dropdown-panel {
            .p-dropdown-header {
                padding: 0.30rem 0.5rem;
            }

            .p-dropdown-items .p-dropdown-item.p-highlight {
                background-color: $color_primary;
            }
        }

        &:not(.p-disabled),
        &:not(.p-disabled).p-focus {
            border-color: #e6e6e6;
        }

        &.p-disabled {
            opacity: 1;
            background-color: #e9ecef !important;
            border-color: #e6e6e6 !important;
            border-width: 2px;
            .p-inputtext { color: #495057; }
            .p-dropdown-trigger { display: none; }
        }
    }

    .is-invalid {
        .p-dropdown,
        &:hover .p-dropdown,
        .p-dropdown.p-dropdown-open {
            border-color: #dc3545;
        }
        .p-dropdown.p-dropdown-open {
            box-shadow: 0 0 0 .2rem rgba(220,53,69,.25) !important;
        }
        .p-dropdown-trigger-icon {
            color: #dc3545;
        }
    }

    .input-group-sm .p-dropdown {
        height: calc(1.5em + .5rem + 2px);

        .p-dropdown-label {
            padding: .25rem .5rem;
            font-size: .875rem;
            line-height: 1.5;
        }
    }



    .p-checkbox .p-checkbox-box.p-highlight {
        background-color: $color_primary;
        border-color: $color_primary;
    }

    .p-checkbox-label {
        margin-bottom: 0 !important;
    }

    .p-tabview {
        a, a:link, a:hover, a:visited {
            text-decoration: none;
        }

        .p-tabview-nav li .p-tabview-nav-link {
            border-bottom-width: 2px;
            border-color: transparent transparent #E0E0E0 transparent;
        }

        .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
            border-color: transparent transparent #828282 transparent;
        }

        .p-tabview-nav li.p-highlight .p-tabview-nav-link {
            border-color: transparent transparent #fff transparent;
            color: #fff;
        }
    }

    .p-breadcrumb {
        background: none;
        margin: 0;
        padding: 0.5em 0;

        ul li .p-menuitem {
            word-break: break-word;
        }

        ul li:last-child {
            display: inline-flex;
            align-items: center;

            .p-menuitem-link {
                overflow: hidden;
                display: inline-block;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        ul li .p-menuitem-link:focus,
        ul li .p-menuitem-link:active {
            outline: none;
            box-shadow: none;
        }

        li:not(:last-child)  {
            .p-menuitem-text, .p-menuitem-icon {
                color: $color_primary !important;
            }
        }

        li:not(:last-child) a:hover .p-menuitem-text {
            text-decoration: underline;
        }
    }

    .p-datepicker {
        table.p-datepicker-calendar {
            table-layout: fixed;
        }
        .p-datepicker-header {
            height: 60px;
        }
        .p-datepicker-header .p-datepicker-prev:focus,
        .p-datepicker-header .p-datepicker-next:focus {
            box-shadow: none !important;
        }

        .p-datepicker-buttonbar {
            padding: 0.5rem;
        }

        .p-datepicker-calendar thead th { text-align: center }

        table td {
            & > span {
                width: 2.0rem;
                height: 2.0rem;
            }

            &.p-datepicker-today > span.p-highlight,
            & > span.p-highlight {
                background-color: $color_primary;
                box-shadow: none !important;
            }
        }

        .p-timepicker {
            & > div { flex-direction: row-reverse; }
            button > span {transform: rotate(90deg) }
            .p-hour-picker > button {
                margin: 0;
                padding: 0;
            }

            .p-minute-picker button,
            .p-ampm-picker button {
                box-shadow: none !important;
            }

            .p-ampm-picker > span { font-family: monospace }
        }

        .p-monthpicker .p-monthpicker-month.p-highlight,
        .p-yearpicker .p-yearpicker-year.p-highlight {
            background: $color_primary;
        }

        &:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus,
        &:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus,
        &:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
            box-shadow: none !important;
        }
    }

    .p-inputtext:enabled:focus {
        box-shadow: none;
        border-color: #ced4da;
    }

    .is-invalid .p-inputtext {

        &, &:hover {
            border-color: #dc3545;
            padding-right: calc(1.5em + .75rem);
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right calc(.375em + .1875rem) center;
            background-size: calc(.75em + .375rem) calc(.75em + .375rem);
        }

        &:focus {
            border-color: #dc3545;
            box-shadow: 0 0 0 .2rem rgba(220,53,69,.25) !important;
        }

        &.custom {
            margin-right: 3.25rem;
        }

        & + .p-dropdown-filter-icon {
            margin-right: 20px;
        }
    }

    .p-menu {
        width: 15rem;
        max-width: 100%;
    }

    .p-autocomplete {
        .p-inputtext {
            border-width: 2px !important;
            box-shadow: none !important;
            border-color: $color_input_border_default !important;
        }

        .p-highlight {
            background-color: $color_primary !important;
        }

        .p-autocomplete-token {
            background-color: $color_primary !important;
            margin: 0.15rem 0;
            border-radius: 50px !important;
            padding: 0.22rem 0.4rem 0.22rem 0.7rem !important;

            span.p-autocomplete-token-label {
                font-size: 13px;
            }
        }

        .p-autocomplete-panel {
            right: 0;
            .p-autocomplete-items {
                .p-autocomplete-item {
                    @media screen and (max-width: 991px) {
                        padding-left: 0.5rem;
                        padding-right: 0.5rem;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }

    .p-slider {
        &:not(.p-disabled) .p-slider-handle {
            border-radius: 50%;
            background: $color_job_regular;
            border-color: $color_job_regular;

            &:hover {
                background: darken($color_job_regular, 10%);
                border-color: darken($color_job_regular, 10%);
            }

            &:focus {
                box-shadow: 0 0 0 0.2rem rgba($color_job_regular, 0.5);
            }
        }

        .p-slider-range {
            background: $color_job_regular;
            color: $color_job_regular;
        }
    }

    .p-calendar {
        .p-datepicker-title {
            display: flex;

            .p-datepicker-year:enabled:hover,
            .p-datepicker-month:enabled:hover {
                color: $color_primary !important;
            }

            select.p-datepicker-month,
            select.p-datepicker-year {
                display: block;
                width: 100%;
                height: calc(1.5em + .75rem + 2px);
                padding: .375rem .75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #495057;
                background-color: #fff;
                background-clip: padding-box;
                border: 2px solid #ced4da;

                height: calc(1.5em + .5rem + 2px);
                padding: .25rem .5rem;
                font-size: .875rem;
                line-height: 1.5;
                border-radius: .2rem;
            }

            select.p-datepicker-month { flex: 2 }
            select.p-datepicker-year { flex: 1 }

            .p-link:focus {
                box-shadow: none !important;
            }
        }
    }

    .p-treeselect-panel .p-treeselect-header {
        padding: 0.25rem 0.50rem;
    }
    .p-tree {
        padding: 0.25rem;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content:focus,
    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
        box-shadow: inset 0 0 0 0.15rem rgba($color_primary, 0.5);
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
        background-color: $color_primary;
    }

    .p-treeselect,
    .p-multiselect {
        border-width: 2px;
        border-color: $color_border;
        border-radius: 0.375rem;

        &:not(.p-disabled).p-focus {
            border-color: $color_border;
            box-shadow: none;
        }
    }

    .p-multiselect.p-disabled{
        background-color: #e9ecef !important;
    }

    .p-multiselect-panel {
        .p-multiselect-header {
            padding: 0.3rem 0.5rem;

            .p-inputtext {
                padding: 0.375rem 0.75rem
            }
        }
        .p-multiselect-items-wrapper {
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

            @media screen and (min-width: 992px) {
                // max-height: 50vh !important;
            }
        }
        .p-multiselect-items {
            .p-multiselect-item {
                padding: 0.25rem 1.25rem 0.25rem 0.75rem ;

                &.p-highlight {
                    color: $color_text;
                    background: transparent;
                }

                &:focus {
                    box-shadow: none;
                }

                .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
                    background: $color_primary;
                    box-shadow: none;
                    border: 0;
                }
            }
        }
    }

    & > .p-multiselect-panel {
        z-index: 1100 !important;
    }

    .is-invalid .p-multiselect-panel .p-multiselect-filter-container > .p-multiselect-filter.p-inputtext {
        background-image: none
    }

    p-galleriacontent {
        .p-galleria-close {
            z-index: 1;
        }

        .p-galleria-content {
            .p-galleria-item img {
                max-width: 90vw;
                max-height: 90vh;
                object-fit: contain;
            }

            .p-galleria-item-nav {
                z-index: 1;
            }
        }
    }

    & > .p-autocomplete-panel {
        z-index: 1030 !important;
    }

    .p-datepicker:not(.p-datepicker-inline) {
        max-width: min(300px, 100vw);
    }
}

.p-dialog {
    @include media-breakpoint-up(md) {
        max-width: 70vw;
    }

    @include media-breakpoint-up(lg) {
        margin-left: 135px !important;
    }
}
