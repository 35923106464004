@import '~bootstrap/scss/mixins/breakpoints';
@import 'variables';
@import 'colors';
@import 'fonts';

a {
    color: #007bff;
    text-decoration: none;

    &:hover {
        color: #0056b3;
        text-decoration: underline;
    }
}

.clearfix {
    &::after {
        display: block;
        content: "";
        clear: both;
    }
}

$positions: static, relative, absolute, fixed, sticky !default;

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        // Common values
        @each $position in $positions {
            .position#{$infix}-#{$position} { position: $position !important; }
        }
    }
}

.page-item {
    a {
        text-decoration: none;
    }
    .page-link {
        color: $color_primary;
    }

    &.active .page-link {
        background-color: $color_primary;
        border-color: $color_primary;
        color: $color_text_invert;
    }
}

a.dropdown-item {
    color: #212529;
    &:hover {
        text-decoration: none;
    }
}
.dropdown-item.active, .dropdown-item:active {
    background-color: $color_primary;
}
.dropdown-menu {
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
}

.table {
    td, th {
        vertical-align: middle;
    }

    &.table-striped > tbody {
        & > tr:nth-of-type(2n+1) > * {
            background-color: transparent;
            --bs-table-accent-bg: transparent;
        }
        & > tr:nth-of-type(2n+2) > * {
            background-color: rgba(0,0,0,.05);
        }
    }
    &.table-hover > tbody > tr:hover > * {
        background-color: rgba(0, 0, 0, 0.05);
    }

    .table-light th {
        color: #495057;
        background-color: #e9ecef;
        border-color: #dee2e6;
    }
}

.btn-round {
    border-radius: 12px;
}


a.nav-link,
a.btn {
    &, &:hover {
        text-decoration: none;
    }
}

.btn {
    background-image: none;
}

.nav-tabs:not(.card-header-tabs) {
    border: none;
    padding-bottom: 1.25em;

    .nav-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        &.selected {
            margin-bottom: -1px;
        }

        &:focus-visible,
        .nav-link:focus-visible {
            border: 0 !important;
            outline: none !important;
            box-shadow: none !important;
        }
    }

    .nav-button,
    .nav-link {
        border-style: solid;
        border-width: 0 0 3px 0;
        border-color: transparent transparent #E0E0E0 transparent;
    }

    .nav-link {
        font-size: 1.25rem;
        color: #828282;
        cursor: pointer;
        text-decoration: none;
        font-weight: bold;

        @media screen and (max-width: 991px) {
            font-size: 1.0rem;
            padding: 0.5rem 0.75rem
        }

        @media screen and (max-width: 767px) {
            font-size: 0.875rem;
            padding: 0.5rem 0.5rem
        }

        &:hover:not(.nav-button) {
            border-width: 0 0 3px 0;
            border-color: transparent transparent #828282 transparent;
        }

        &.active, &.active:hover {
            color: $color_primary;
            background: transparent;
            border-width: 0 0 5px 0;
            border-color: transparent transparent $color_primary transparent;
        }
    }
}

.overflow-visible {
    overflow: visible !important
}

.form-switch {
    .form-check-input:checked ~ .form-check-label::before {
        border-color: #56CC87;
        background-color: #56CC87;
    }

    .form-check-input:checked {
        background-color: $color_primary;
        border-color: $color_primary;
    }
    .form-check-input:focus:not(:checked) {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 8 8"><circle r="3" fill="%23FF1F35" fill-opacity="0.5"/></svg>');
    }

    .form-check-input:focus {
        box-shadow: 0 0 0 0.25rem rgba($color_primary, 0.25);
        border-color: lighten($color_primary, 25%);
    }
}

.text-primary  {
    color: $color_primary !important;
}

.text-approve {
    color: $color_approve !important;
}

.input-group {

    .btn {
        line-height: normal;
        position: relative;
        z-index: 3;
    }

    .form-control:focus + .btn {
        z-index: 5;
    }
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active {
    background-color: $color_primary;
}

.bg-primary {
    background-color: $color_primary !important;
}
.border-primary {
    border-color: $color_primary !important;
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .border#{$infix}-top {      border-top: $border-width solid $border-color !important; }
        .border#{$infix}-end {    border-right: $border-width solid $border-color !important; }
        .border#{$infix}-bottom {   border-bottom: $border-width solid $border-color !important; }
        .border#{$infix}-start {     border-left: $border-width solid $border-color !important; }

        .border#{$infix}-top-0 {    border-top: 0 !important; }
        .border#{$infix}-end-0 {  border-right: 0 !important; }
        .border#{$infix}-bottom-0 { border-bottom: 0 !important; }
        .border#{$infix}-start-0 {   border-left: 0 !important; }

        .border#{$infix}-x {
            border-left: $border-width solid $border-color !important;
            border-right: $border-width solid $border-color !important;
        }

        .border#{$infix}-y {
            border-top: $border-width solid $border-color !important;
            border-bottom: $border-width solid $border-color !important;
        }
    }
}

@include media-breakpoint-down(md) {
    .dropdown-item {
        padding: .75rem 1.5rem
    }
}

.badge {
    &.bg-primary {
        background-color: $color_approve !important;
    }
    &.bg-danger {
        background-color: $color_primary !important;
    }

    &.bg-warning, &.bg-light, &.bg-white, &.bg-info {
        color: $color_secondary;
    }
}

a.badge {
    text-decoration: none;
    &, &:hover {
        color: $color_text_invert;
    }
}

.page-link:focus {
    box-shadow: 0 0 0 .2rem rgba($color_primary, 0.25)
}


@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        .col-#{$breakpoint}-5th {
            flex: 0 0 20%;
            max-width: 20%;
        }
    }
}

.container-wider {
    @media screen and (min-width: 1700px) {
        max-width: 1640px !important;
    }
}

.rounded-xl {
    border-radius: 0.5rem;
}

form label {
    @media screen and (max-width: 991px) {
        font-weight: bold;
    }
}

.card {
    .card-header.collapsed {
        border-bottom-color: transparent;
    }
}

.card.accordion-item {
    color: var(--bs-card-color);
    background-color: var(--bs-card-bg);
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);

    &:first-of-type {
        border-top-left-radius: var(--bs-card-border-radius);
        border-top-right-radius: var(--bs-card-border-radius);
    }
    &:last-of-type {
        border-bottom-right-radius: var(--bs-card-border-radius);
        border-bottom-left-radius: var(--bs-card-border-radius);
    }

    .card-body {
        & > br:last-child,
        & > hr:last-child {
            display: none;
            margin: 0;
            padding: 0;
        }
    }

    & > .card-header,
    & > .collapse > .card-header {
        & > .btn {
            text-decoration: none;
            font-weight: bold;
            position: relative;
            padding-right: 40px;

            &:after {
                content: "\f106";
                font-family: $font_family_icons;
                font-weight: 900;
                font-style: normal;
                text-align: center;
                width: 1.25em;
                top: 50%;
                right: 10px;
                position: absolute;
                transform: translateY(-50%) rotate(-180deg);
                transition: 0.2s all;
            }

            &:not(.collapsed) {
                &:after {
                    transform: translateY(-50%) rotate(0);
                }
            }
        }
    }

    @media screen and (min-width: 992px) {
        .collapse:not(.default-behavior) {
            display: block;
        }
    }
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.offcanvas-backdrop {
    z-index: 1030;

    @media screen and (min-width: 992px) {
        display: none;
        width: 1px;
        height: 1px;
    }
}

.card-redesign {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 12px !important;

    &.accordion-item {
        @media screen and (max-width: 991px) {
            margin-bottom: 16px !important;
        }
    }

    & > .card-header {
        background-color: #e3e3e3;
        border-radius: 12px 12px 0 0;
        border-bottom: 0;
        border: 0;

        @media screen and (max-width: 991px) {
            background-color: transparent;

            & > .btn {
                text-align: center !important;
                color: #534848;
            }
        }

        & > .btn {
            background-color: #e3e3e3;
            border-radius: 12px 12px 0 0;
            transition: all 0.6s;
            box-shadow: none;

            &.collapsed {
                border-radius: 12px;
                box-shadow: 0 0 0px 5px #f7f7f7;
            }
        }
    }

    & > .card-body {
        border-top: 0 !important;
    }
}


.callout {
    padding: 15px;
    margin: 15px 0;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;

    h4 {
        margin-top: 0;
        margin-bottom: 5px;
    }

    p:last-child {
        margin-bottom: 0;
    }

    code {
        border-radius: 3px;
    }

    & + .bs-callout {
        margin-top: -5px;
    }
}

@each $name,$color in (default,#777),(primary,#428bca),(success,#5cb85c),(danger,#d9534f),(warning,#f0ad4e),(info,#5bc0de), (bdc,#29527a) {
    .callout-#{$name} {
        border-left-color: $color;
        h4 {
            color: $color;
        }
    }
}

form:not(.view-mode) label.required:after {
    content: "*";
    display: inline-block;
    color: $color_primary_light;
    padding-left: 3px;
}

.text-keep-newlines {
    white-space: pre-line;
}
