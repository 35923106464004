$bgcolor_body: #35363e;
$bgcolor_default: #fff;
$bgcolor_headline: #35363e;
$bgcolor_page: #f7f7f7;
$bgcolor_success: #69d98c;
$bgcolor_scrollbar: #e0e0e0;
$bgcolor_section: #e6e6e6;
$bgcolor_toolbar: #e4e4e4;
$bgcolor_inactive: #c2bfbf;

$color_text: #000;
$color_text_invert: #fff;
$color_text_inactive: #aaa;
$color_text_value: #666;
$color_text_unselect: #888;
$color_chat: #fff;
$color_chat_date: #333;
$color_chat_me: #707D99;
$color_chat_highlight: #FFB761;
$color_link: #007bff;
$color_link_hover: #0056b3;

$color_primary: #BE0C1E;
$color_primary_light: #FF1F35;
$color_secondary: #29334E;
$color_approve: #009afa;
$color_highlight: $color_approve;
$color_highlight_invert: #FF974C;
$color_select: #FFB761;
$color_regular: #ff5959;

$color_border: #e6e6e6;
$color_border_dark: #999;
$color_headline_border: #6a6a6a;
$color_input_border_default: $color_border;

$bgcolor_header: $bgcolor_default;

$color_photo_default: #999999;
$color_photo_hover: #666;

$color_job_default: #999999;
$color_job_instant: #FFB761;
$color_job_regular: $color_regular;
$color_job_quote: #4FBCFF;
$color_job_other: #FF974C;

$color_job_border: #ccc;
$color_job_status: #e0e0e0;

$color_steps_border: #aaa;
$color_steps_inactive: #666;
$color_steps_arrow: #b7b7b7;
$color_steps_active: $color_primary;

$job_type_hover_percentage: 7%;
$job_type_focus_percentage: 23%;

$color_outline_btn_disabled_text: $color_text_value;
$color_outline_btn_disabled_border: $color_text_value;


$color_job:$color_job_instant, $color_job_regular, $color_job_quote, $color_job_default;

.task-type-color {
    $color: $color_job_other;

    color: $color;
    border-color: $color;
    background-color: $color;

    &.btn {
        &:hover { background-color: lighten($color, $job_type_hover_percentage) }
        &:focus { box-shadow: 0 0 0 .2rem lighten($color, $job_type_focus_percentage) }
    }

    @for $i from 1 through length($color_job) {
        &.color-#{$i} {
            $color: nth($color_job, $i);

            color: $color;
            border-color: $color;
            background-color: $color;

            &.btn {
                &:hover { background-color: lighten($color, $job_type_hover_percentage) }
                &:focus { box-shadow: 0 0 0 .2rem lighten($color, $job_type_focus_percentage) }
            }
        }
    }
}

.task-type-color-border {
    $color: $color_job_other;

    border-color: $color;

    @for $i from 1 through length($color_job) {
        &.color-#{$i} {
            $color: nth($color_job, $i);
            border-color: $color;
        }
    }
}
